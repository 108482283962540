<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['供应商档案']" :chooseCrumbData="1"></bread-crumb>
      <search-case :searchData="searchData" @inquireBtnClick="inquireBtnClick"></search-case>
      <div style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        ">
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="handleAdd">
            <!-- <div style="display: flex; align-items: center; font-size: 14px"> -->
            <!-- <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              /> -->
            <span style="margin-left: 3px">新增</span>
            <!-- </div> -->
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="batchDelete">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" src='src/assets/iconImg/logo.png' />
              <span style="margin-left: 3px">批量删除</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
          @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="supplier_code" sortable label="编码"></el-table-column>
          <el-table-column prop="supplier_name" sortable label="名称"></el-table-column>
          <el-table-column prop="supplier_web" sortable label="网站"></el-table-column>
          <el-table-column prop="arrears" sortable label="欠款"></el-table-column>
          <el-table-column prop="barrel" sortable label="欠桶"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i class="el-icon-delete poiner" @click="handleDelete(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="tableBottom">
          <span>显示第&nbsp;{{
            tableData.length == 0 ? 0 : (page - 1) * length + 1
          }}&nbsp;至&nbsp;{{
  (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>

          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-button @click="onePage">首页</el-button>
            <el-button @click="backPage">上页</el-button>
            <el-pagination background layout=" pager,slot" :total="tableFinshNum" :current-page="page"
              @current-change="currentChange"></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div> -->
        <pagination :handleQuery="getTabList" :currentPage="page" :currentLength="length" :total="tableFinshNum">
        </pagination>
      </div>
    </div>
    <add v-if="isShow == 1" @close="onClose" :data="data"></add>
    <edit v-if="isShow == 2" @close="onClose" :data="data"></edit>
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
import add from "./childrens/add.vue";
import edit from "./childrens/edit.vue"
export default {
  components: {
    add, pagination, edit
  },
  data() {
    return {
      isShow: 0,
      searchData: {
        date: "",
        QueryContent: "",
        inquirePlaceholder: "输入要查询的编码或名称",
        isInquireBtn: true,
        isInquireValue: true,
      },
      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercial_id: "",
      data: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    onClose(data) {
      this.isShow = data;
      this.getTabList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    inquireBtnClick(data) {
      console.log(data);
      this.$http
        .post(
          "/inventory/supplier/search",
          {
            commercial_id: this.commercial_id,
            search: data.inquireValue,
            firstTime: data.dateRange[0],
            lastTime: data.dateRange[1],
            currentPage: this.page,
            currentLength: this.length
          }
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    getTabList(page = 1) {
      this.$http
        .post("/inventory/supplier/lst", {
          currentPage: page,
          currentLength: this.length,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleEdit(row) {
      this.data = row;
      this.isShow = 2;
    },
    handleAdd() {
      this.data = {
        "commercial_id": this.commercial_id,
        "supplier_code": "",
        "supplier_name": "",
        "supplier_web": "",
        "arrears": "0",
        "barrel": "0"
      };
      this.data.type = "add"
      this.isShow = 1;

    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .post(
            "/inventory/supplier/del",
            this.$qs.stringify({
              id: row.id,
            })
          )
          .then((res) => {
            this.$message({ type: res.data.status, message: res.data.msg });
            this.getTabList();
          });
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

    },
    batchDelete() {
      if (this.multipleSelection.length === 0) return;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const arr = this.multipleSelection.map((item) => {
          return item.id;
        });
        const idArr = arr.join(",");
        this.$http
          .post("/inventory/supplier/delAll", this.$qs.stringify({ idArr }))
          .then((res) => {
            this.$message({
              type: res.data.status,
              message: res.data.msg,
            });
            this.getTabList();
          });
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    backPage() {
      this.page = --this.page;
      this.getTabList();
    },
    nextPage() {
      this.page = ++this.page;
      this.getTabList();
    },
    currentChange(e) {
      this.page = e;
      this.getTabList();
    },
    onePage() {
      this.page = 1;
      this.getTabList();
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
      this.getTabList();
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .cell {
  padding: 0 !important;
}
</style>
