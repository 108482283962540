<template>
  <div>
    <bread-crumb :crumbData="['供应商档案', '新增']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
      style="padding: 50px; width: 40%">
      <el-form-item label="编码：" prop="supplier_code">
        <el-input v-model="ruleForm.supplier_code"></el-input>
      </el-form-item>
      <el-form-item label="名称：" prop="supplier_name">
        <el-input v-model="ruleForm.supplier_name"></el-input>
      </el-form-item>
      <el-form-item label="官网：" prop="supplier_web">
        <el-input v-model="ruleForm.supplier_web"></el-input>
      </el-form-item>
      <!-- <div v-if="data">
        <el-form-item label="欠款：">
          <div class="row">
            <div class="row-num">￥{{ ruleForm.arrears }}</div>
            <span class="el-icon-edit" @click="editBalance(true)"></span>
            <span class="el-icon-search" @click="searchClick(true)"></span>
          </div>
        </el-form-item>
        <el-form-item label="欠桶：">
          <div class="row">
            <div class="row-num">{{ ruleForm.barrel }}</div>
            <span class="el-icon-edit" @click="editBalance(false)"></span>
            <span class="el-icon-search" @click="searchClick(false)"></span>
          </div>
        </el-form-item>
      </div> -->
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="记账" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
      <el-form label-width="100px" :model="arrearsForm" :rules="dialogRules" ref="arrearsForm" style="width: 100%"
        v-if="isArrears">
        <el-form-item label="供应商：">
          <div>{{ arrearsForm.supplier_name }}</div>
        </el-form-item>
        <el-form-item :label="'当前欠款：'">
          <div>{{ arrearsForm.arrears }}</div>
        </el-form-item>
        <el-form-item label="记账操作：">
          <el-radio v-model="arrearsForm.type" :label="0">加</el-radio>
          <el-radio v-model="arrearsForm.type" :label="1">减</el-radio>
        </el-form-item>

        <el-form-item label="金额：" prop="money">
          <el-input v-model="arrearsForm.money" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="arrearsForm.remark" type="textarea" rows="4"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitDialog('arrearsForm')">提交</el-button>
        </el-form-item>
      </el-form>
      <el-form label-width="100px" :model="barrelForm" :rules="barreRules" ref="barrelForm" style="width: 100%" v-else>
        <el-form-item label="供应商：">
          <div>{{ barrelForm.supplier_name }}</div>
        </el-form-item>
        <el-form-item :label="'当前欠桶：'">
          <div>{{ barrelForm.barrel }}</div>
        </el-form-item>
        <el-form-item label="记账操作：">
          <el-radio v-model="barrelForm.type" :label="0">加</el-radio>
          <el-radio v-model="barrelForm.type" :label="1">减</el-radio>
        </el-form-item>
        <el-form-item :label="'品牌类型：'">
          <el-select v-model="barrelForm.brandType" placeholder="请选择">
            <el-option v-for="item in brandTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量：" prop="num">
          <el-input v-model="barrelForm.num" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="barrelForm.remark" type="textarea" rows="4"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitDialog('barrelForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="tabVisible" width="50%" :before-close="tabClose">
      <div class="row">
        <div class="title">日期范围：</div>
        <el-date-picker v-model="date1" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 150px">
        </el-date-picker>
        -
        <el-date-picker v-model="date2" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 150px">
        </el-date-picker>
        <el-button type="success" icon="el-icon-search">查询</el-button>
        <el-button type="success" icon="el-icon-delete">清除</el-button>
        <el-button type="primary">
          <div class="button-box">
            <span class="spriteChart_daochu" style="display: inline-block"></span>导出报表
          </div>
        </el-button>
      </div>
      <el-table :data="tableData" border>
        <el-table-column prop="date" label="日期"> </el-table-column>
        <!-- <el-table-column prop="date" label="桶品牌"> </el-table-column> -->
        <el-table-column prop="date" label="操作人"> </el-table-column>
        <el-table-column prop="date" label="备注"> </el-table-column>
        <el-table-column prop="date" label="订单编号"> </el-table-column>
        <el-table-column prop="date" label="上期欠款"> </el-table-column>
        <el-table-column prop="date" label="变化值"> </el-table-column>
        <el-table-column prop="date" label="本期欠款"> </el-table-column>
      </el-table>
      <div class="tableBottom">
        <span>显示第&nbsp;{{
          tableData.length == 0 ? 0 : (currentPage - 1) * currentLength + 1
        }}&nbsp;至&nbsp;{{
  (currentPage - 1) * 10 + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>
        <div style="display: flex; justify-content: flex-end; align-items: center">
          <el-pagination background layout="prev, pager, next" :total="tabletotalNum" :current-page="currentPage"
            @current-change="currentChange">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      ruleForm: {
        supplier_code: "",
        supplier_name: "",
        supplier_web: "",
        commercial_id: "",
      },
      rules: {
        supplier_code: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        supplier_name: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        supplier_web: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
      },
      barreRules: {
        remark: [{ required: true, message: "必填字段", trigger: "blur" }],
        num: [{ required: true, message: "必填字段", trigger: "blur" }],
      },
      arrearsForm: {
        supplier_name: "",
        arrears: "",
        type: 0,
        money: "",
        remark: "",
        id: "",
      },
      barrelForm: {
        id: "",
        supplier_name: "",
        type: 0,
        barrel: "",
        brandType: "",
        num: "",
        remark: "",
      },
      brandTypes: [],
      dialogRules: {
        money: [{ required: true, message: "必填字段", trigger: "blur" }],
        remark: [{ required: true, message: "必填字段", trigger: "blur" }],
      },
      tableData: [],
      dialogVisible: false,
      tabVisible: false,
      date1: "",
      date2: "",
      isArrears: false,
      currentPage: 1,
      currentLength: 10,
      tabletotalNum: "",
    };
  },
  mounted() {
    console.log(this.$props.data);
    if (this.$props.data) {
      this.ruleForm = this.$props.data;
      this.arrearsForm.id = this.$props.data.id;
      this.arrearsForm.supplier_name = this.$props.data.supplier_name;
      this.arrearsForm.arrears = this.$props.data.arrears;
      this.barrelForm.id = this.$props.data.id;
      this.barrelForm.supplier_name = this.$props.data.supplier_name;
      this.barrelForm.barrel = this.$props.data.barrel;
    }
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/inventory/supplier/add", this.$qs.stringify(this.ruleForm))
            .then((res) => {
              this.$message({
                type: res.data.status,
                message: res.data.msg,
              });
              this.onClose(0);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitDialog(forName) {
      let obj = {};
      if (this.isArrears) {
        obj = this.arrearsForm;
      } else {
        obj = this.barrelForm;
      }
      this.$refs[forName].validate((valid) => {
        if (valid) {
          console.log(this.arrearsForm);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    editBalance(state) {
      this.isArrears = state;
      this.dialogVisible = true;
    },
    tabClose() {
      this.tabVisible = false;
    },
    searchClick(state) {
      this.isArrears = state;
      this.tabVisible = true;
    },
    currentChange(page) {
      this.currentPage = page;
    },
  },
};
</script>
<style lang="less" scoped>
.row {
  display: flex;
  align-items: center;

  &-num {
    width: 370px;
  }

  span {
    margin-right: 10px;
    cursor: pointer;
  }
}

/deep/ .el-dialog__header {
  background-color: #f6f6f6;
  padding: 10px;
  border-bottom: 1px solid #ececec;
}

/deep/ .el-dialog__headerbtn {
  top: 15px;
}

.button-box {
  display: flex;
  align-items: center;
}
</style>
